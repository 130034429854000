<template>
  <div>
    <transition name="fade-out">
      <div
        v-if="showAppPreloader"
        class="preloader-container"
      >
        <app-preloader
          @complete="onPreloaderComplete"
        />
      </div>
    </transition>
    <template v-if="loadingLocale || !isTabActual">
      <loading class="view-loading view-loading--full-page" />
    </template>
    <template v-else-if="!showAppPreloader">
      <svg-sprite />

      <transition name="fade">
        <div
          v-if="updateExists"
          class="system-update-btn"
          @click="acceptUpdateApp"
        >
          <div class="system-update-btn__body sub-body">
            <div class="sub-body__icon">
              <svg-icon name="arrow-sync" />
            </div>
            <div class="sub-body__text">
              {{ $vDict('global.system_update_available.text') }}
            </div>
          </div>
          <div class="system-update-btn__append">
            {{ $vDict('global.button_update.text') }}
          </div>
        </div>
      </transition>
      <router-view />
    </template>

    <portal-target name="dropdown-popup" />
  </div>
</template>

<script>
import {
  isMobile,
  isIOS,
  isSafari,
  isTablet,
} from 'mobile-device-detect';
import { enableDocumentScrolling } from '@/shared/doc-scrolling';
import {
  updateAppMixin,
  checkActualTabAppMixin,
} from "@/mixins/app";
import SvgSprite from '@/components/SvgSprite';

const AppPreloader = () => import('@/components/AppPreloader');

export default {
  name: 'app',
  components: {
    SvgSprite,
    AppPreloader,
  },
  mixins: [
    updateAppMixin,
    checkActualTabAppMixin,
  ],
  data() {
    return {
      showAppPreloader: false,
    };
  },
  computed: {
    loadingLocale() {
      return this.$store.getters.isLoadingDictRoute('main');
    },
  },
  mounted() {
    // const visited = localStorage.getItem('inited');
    //
    // if (!visited) {
    //   this.showAppPreloader = true;
    // }

    this.checkMobile();
    this.checkUserSubdomain();

    document.querySelector('html').classList.add(`env-mode-${window.envMode}`);
    window.addEventListener('online', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
  },
  updated() {
    enableDocumentScrolling();
  },
  methods: {
    updateOnlineStatus() {
      this.$store.commit('App/SET_NETWORK_OFFLINE', false);
    },
    onPreloaderComplete() {
      this.showAppPreloader = false;
      localStorage.setItem('inited', 'true');
    },
    checkMobile() {
      const html = document.documentElement;
      if (isMobile) {
        html.classList.add('is-mobile');
      } else {
        html.classList.remove('is-mobile');
      }

      if (isIOS) {
        html.classList.add('is-ios');
      } else {
        html.classList.remove('is-ios');
      }

      if (isSafari) {
        html.classList.add('is-safari');
      } else {
        html.classList.remove('is-safari');
      }

      if (isTablet) {
        html.classList.add('is-tablet');
      } else {
        html.classList.remove('is-tablet');
      }
    },
    checkUserSubdomain() {
      if (window.logged_as_user) {
        const html = document.documentElement;
        html.classList.add('logged-as-user');
      }
    },
  },
};
</script>

<style lang="scss">
// Import Bootstrap Vue Styles
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";
</style>
